/* global appLoading, Lockr, nest, umami */
import admin from '../../utils/api/admin'
import swal from 'sweetalert'

import {
    notificationWatcher, money, Modal, getCurrentApp, getCurrentAppId, isMobile
} from '../../utils'
import {
    isLoggedIn, isAdmin, getUser, signOut, isAliasing
} from '../../utils/api/auth'
import { getBalance } from '../../utils/api/app'
import Refresher from '../refresher.vue'
import endAliasing from '../../utils/ui/endAliasSession'

import mountHeader from '../../../assets/js/header'; // eslint-disable-line

export default {
    name: 'mainHeader',
    components: {
        refresher: Refresher
    },
    data: () => ({
        isSandbox: false,
        isShowingApps: false,
        homePath: '/',
        isAdmin: isAdmin(),
        loggedIn: isLoggedIn(),
        isAliasing: isAliasing(),
        user: getUser({ photo: true }),
        walletBalance: {
            currency: null,
            amount: money(0)
        },
        creditBalance: {
            currency: null,
            amount: money(0)
        },
        billingPlan: null,
        listOfPlans: '',
        loadedBalance: false,
        error: null,
        success: null
    }),
    created: async function fx () {
        this.loggedIn = isLoggedIn(this.isAdmin)
    },
    mounted: function fx () {
        mountHeader()
    },
    watch: {
        error: notificationWatcher('error', 'error', 15000),
        success: notificationWatcher('success', 'success'),
        $route: 'routeUpdate'
    },
    filters: {
        fullName: (user) => {
            if (user.firstName) {
                return `${user.firstName} ${user.lastName || ''}`
            }
            return user.username || 'Unnamed User'
        }
    },
    methods: {
        routeUpdate: function fx () {
            if (/admin-login/.test(this.$route.name)) {
                this.homePath = '/admin'
            }
            const app = getCurrentApp()
            this.isShowingApps = app !== null
            this.isSandbox = this.isShowingApps && app && app.sandbox

            if (this.loggedIn && !this.isAdmin) {
                if (!this.walletBalance.currency) {
                    this.refreshBalance()
                }
                if (!this.isShowingApps && getCurrentAppId()) {
                    window.location = '/oops'
                }
            }
        },
        logout: function logout () {
            appLoading.start()
            signOut().then(() => {
                appLoading.stop()
                umami.track('logged-out', 'signup')
                setTimeout(() => { window.location.pathname = this.isAdmin ? '/admin/login' : '/auth/login' }, 300)
            })
        },

        refreshBalance: async function refreshBalance () {
            let canProceed = !this.isSandbox && this.isShowingApps
            if (canProceed) {
                appLoading.start()
                this.loadedBalance = false
                const balance = await getBalance()
                if (!balance) {
                    this.walletBalance = {
                        currency: 'N/A',
                        amount: ''
                    }
                    canProceed = false
                    if (nest.last_kmsg) {
                        this.error = nest.last_kmsg
                        nest.last_kmsg = null
                    } else {
                        const cmd = /^MacIntel$/.test(navigator.platform) ? 'SHIFT + CMD + R' : 'SHIFT + CTRL + R'
                        const msg = isMobile()
                            ? 'Kindly try clear the browser\'s cache and refresh the page.'
                            : `Kindly try a hard refresh by pressing the following combination of keys:<br/><b>${cmd}</b>`
                        this.error = `Failed to refresh balance :(<br/>${msg}`
                    }
                } else {
                    this.walletBalance.currency = balance.walletBalance.currencyCode
                    this.walletBalance.amount = money(balance.walletBalance.amount, 2)
                    this.creditBalance.currency = balance.creditBalance.currencyCode
                    this.creditBalance.amount = money(balance.creditBalance.amount, 2)
                    this.billingPlan = balance.currentBillingPlan
                    this.listOfPlans = ''
                    for (const [key, value] of Object.entries(balance.planBalances)) {
                        this.listOfPlans += `<li><b>${key}:</b> ${value.currencyCode} ${parseFloat(value.amount).toFixed(2)}</li>`
                    }
                    Lockr.set('user-currency', balance.walletBalance.currencyCode)
                }
                this.loadedBalance = true
                appLoading.stop()
            }
            return canProceed
        },

        endAliasing: async function fx () {
            await endAliasing(this.user.email)
        },

        startAliasing: async function fx () {
            appLoading.start()
            const email = document.querySelector('#aliasee').value
            if (!email) {
                this.error = 'Please enter a username'
            } else {
                const started = await admin.startAlias(email)
                if (started) {
                    window.location.pathname = '/'
                } else {
                    this.error = `Could not login as <b>${email}</b>. Make sure the user exists.`
                }
            }
            appLoading.stop()
            return document.querySelector('.js-modal-close').click()
        },

        showAliasingDialog: () => {
            const modal = new Modal({
                close: '.js-modal-close',
                modal: '.js-modal',
                modalInner: '.js-modal-inner',
                modalContent: '.js-modal-content-inner',
                class: 'modal--visible',
                onOpen: () => {
                    document.querySelector('#aliasee').focus()
                }
            })
            modal.open('#alias-dialog')
        },
        showBillingPlanDetails: function () {
            const span = document.createElement('span')
            span.innerHTML = `<p style="text-align: left;">This is a list of your billing plans and their balances:<ul style="list-style-type:none; text-align: left;">${this.listOfPlans}</ul></p><p style="text-align: left;">For more information, please visit our <a style="color: var(--primaryColor);" href="https://africastalking.com/pricing" target="blank">pricing pages</a>.</p>`
            swal({
                title: 'Billing Plans',
                content: span,
                closeOnClickOutside: true,
                closeOnEsc: true
            })
        }
    }
}
