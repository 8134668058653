import aitrime from './products/airtime'
import insights from './products/insights'
import billing from './products/billing'
import dashboard from './dashboard'
import mobileData from './products/mobileData'
import sms from './products/sms'
import ussd from './products/ussd'
import chat from './products/chat'
import voice from './products/voice'
import settings from './settings'
import productRequest from './products/productRequest'

const routes = [].concat(
    dashboard,
    billing,
    aitrime,
    insights,
    ussd,
    mobileData,
    voice,
    sms,
    chat,
    settings,
    productRequest
).map((route) => {
    route.path = `/apps/:appId${route.path}`; // eslint-disable-line
    return route
})

export default routes
