/* global Cookies, nest */
import { jwtDecode } from 'jwt-decode'

import invoke, { COOKIES } from './invoke'
import { isEmpty, getDomain } from '../index'
import { trimSpaces } from '../filters'

export async function changePassword (
    password,
    oldPassword,
    resetToken = null,
    clientId = null
) {
    try {
        const params = {}
        if (!isEmpty(password)) {
            params.password = password
            params.passwordVerify = password
        }

        if (!isEmpty(oldPassword)) {
            params.oldPassword = oldPassword
        }

        const options = {}
        if (resetToken) {
            options.noAuth = true
            options.headers = {
                Authorization: `Bearer ${resetToken}`,
                'X-Client-Id': clientId
            }
        }
        await invoke('POST', '/auth/password/change', params, options)
        return true
    } catch (ex) {
        return false
    }
}

export async function verifyUser (code) {
    try {
        const response = await invoke('POST', '/user/verify', { code })
        const user = jwtDecode(response.access_token)
        Cookies.set(COOKIES.TOKEN, response.access_token, {
            expires: new Date(user.exp * 1000),
            domain: getDomain()
        })
        return user
    } catch (ex) {
        return null
    }
}

export async function sendVerification () {
    try {
        await invoke('POST', '/user/verify/request', {
            userVerificationUri: `${nest.baseUrl}/auth/verify` // ?code=ABSGD
        })
        return true
    } catch (ex) {
        return false
    }
}

export async function changeUserEmail (code) {
    try {
        const response = await invoke('POST', '/user/email/change', { code })
        const user = jwtDecode(response.access_token)
        Cookies.set(COOKIES.TOKEN, response.access_token, {
            expires: new Date(user.exp * 1000),
            domain: getDomain()
        })
        return user
    } catch (ex) {
        return null
    }
}

export async function sendEmailVerification (email, password) {
    try {
        await invoke('POST', '/user/email/change/request', {
            email,
            password,
            userVerificationUri: `${nest.baseUrl}/auth/email/change` // ?code=ABSGD
        })
        return true
    } catch (ex) {
        return false
    }
}

export async function mergeUser (code) {
    try {
        const response = await invoke('POST', '/user/merge', { code })
        const user = jwtDecode(response.access_token)
        Cookies.set(COOKIES.TOKEN, response.access_token, {
            expires: new Date(user.exp * 1000),
            domain: getDomain()
        })
        return user
    } catch (ex) {
        return null
    }
}

export async function sendMergeVerification (email, password) {
    try {
        await invoke('POST', '/user/merge/request', {
            email,
            password,
            userVerificationUri: `${nest.baseUrl}/auth/merge` // ?code=ABSGD
        })
        return true
    } catch (ex) {
        return false
    }
}

export async function inviteToSlack (email) {
    try {
        await invoke('POST', '/user/invite/slack', trimSpaces({ email }))
        return true
    } catch (ex) {
        return false
    }
}

export async function getDevProfile () {
    try {
        return await invoke('GET', '/user/profile/developer')
    } catch (ex) {
        return null
    }
}

export async function getUserProfile () {
    try {
        return await invoke('GET', '/user/profile')
    } catch (ex) {
        return null
    }
}

export async function getLocale () {
    try {
        return await invoke('GET', '/user/locale', null, { noAuth: true })
    } catch (ex) {
        return null
    }
}

export async function updateDevProfile (data) {
    try {
        await invoke('POST', '/user/profile/developer', trimSpaces(data))
        return true
    } catch (ex) {
        return false
    }
}

export async function updateUserProfile (data) {
    try {
        await invoke('POST', '/user/profile', trimSpaces(data))
        return true
    } catch (ex) {
        return false
    }
}

export async function submitUserQuery (data) {
    try {
        await invoke('POST', '/user/query', trimSpaces(data))
        return true
    } catch (ex) {
        return false
    }
}

export async function fetchClients () {
    try {
        return await invoke('GET', '/user/clients')
    } catch (ex) {
        return null
    }
}

export async function revokeClientAccess (clientId) {
    try {
        await invoke('DELETE', `/user/clients/${clientId}`)
        return true
    } catch (ex) {
        return false
    }
}

export async function checkMemberEmail (email) {
    try {
        await invoke('GET', `/user/exists?email=${trimSpaces(email)}`)
        return true
    } catch (ex) {
        return false
    }
}

export async function update2fa (data) {
    try {
        await invoke('POST', '/user/2fa', trimSpaces(data))
        return true
    } catch (err) {
        return false
    }
}

export async function getViewedFeaturePrompts (feature) {
    try {
        const res = await invoke('GET', `/user/feature/${feature}/viewed`)
        return res.prompts
    } catch (ex) {
        return false
    }
}

export async function addViewedFeaturePrompt (feature, prompts) {
    try {
        await invoke('POST', `/user/feature/${feature}/viewed`, { prompts })
        return true
    } catch (ex) {
        return false
    }
}
