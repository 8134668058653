/* eslint-disable import/no-dynamic-require, global-require */
const Inbox = (resolve) => require(['./inbox/list/component.vue'], resolve)
const InboxCallback = (resolve) => require(['./inbox/callback/component.vue'], resolve)

const Shortcodes = (resolve) => require(['./shortcodes/list/component.vue'], resolve)
const ShortcodeRequest = (resolve) => require(['./shortcodes/request/component.vue'], resolve)

const Keywords = (resolve) => require(['./keywords/list/component.vue'], resolve)
const KeywordRequest = (resolve) => require(['./keywords/request/component.vue'], resolve)

const Alphanumerics = (resolve) => require(['./alphanumerics/list/component.vue'], resolve)
const AlphanumericBlacklist = (resolve) => require(['./alphanumerics/blacklist/component.vue'], resolve)
const AlphanumericRequest = (resolve) => require(['./alphanumerics/request/component.vue'], resolve)

const DlrCallback = (resolve) => require(['./dlr/callback/component.vue'], resolve)

const SmppAccounts = (resolve) => require(['./smpp/accounts/component.vue'], resolve)

const PremiumCallback = (resolve) => require(['./premium/callback/component.vue'], resolve)
const PremiumOutbox = (resolve) => require(['./premium/outbox/component.vue'], resolve)
const PremiumAnalytics = (resolve) => require(['./premium/analytics/component.vue'], resolve)
const PremiumSubscriptions = (resolve) => require(['./premium/subscriptions/component.vue'], resolve)

const BulkCallback = (resolve) => require(['./bulk/callback/component.vue'], resolve)
const BulkOutbox = (resolve) => require(['./bulk/outbox/component.vue'], resolve)
const BulkAnalytics = (resolve) => require(['./bulk/analytics/component.vue'], resolve)

const SandboxShortcodeCreate = (resolve) => require(['./sandbox/shortcodes/create/component.vue'], resolve)
const SandboxPremiumCreate = (resolve) => require(['./sandbox/premium/create/component.vue'], resolve)
const SandboxAlphaCreate = (resolve) => require(['./sandbox/alphanumerics/create/component.vue'], resolve)
/* eslint-enable import/no-dynamic-require, global-require */

const routes = [
    // Inbox
    {
        path: '/sms/inbox', name: 'sms-inbox', component: Inbox, meta: { subtitle: 'Inbox' }
    },
    {
        path: '/sms/inbox/callback', name: 'sms-inbox-callback', component: InboxCallback, meta: { subtitle: 'Inbox Callback' }
    },

    // Alphanumerics
    {
        path: '/sms/alphanumerics', name: 'sms-alpha', component: Alphanumerics, meta: { subtitle: 'Alphanumerics' }
    },
    {
        path: '/sms/alphanumerics/alphanumeric/:alpha', name: 'sms-alpha-blacklist', component: AlphanumericBlacklist, meta: { subtitle: 'Blacklist' }
    },
    { path: '/sms/alphanumerics/request', name: 'sms-alpha-request', component: AlphanumericRequest },

    // Shortcodes
    {
        path: '/sms/shortcodes', name: 'sms-shortcodes', component: Shortcodes, meta: { subtitle: 'Short Codes' }
    },
    { path: '/sms/shortcodes/request', name: 'sms-shortcodes-request', component: ShortcodeRequest },

    // Keywords
    {
        path: '/sms/keywords', name: 'sms-keywords', component: Keywords, meta: { subtitle: 'Keywords' }
    },
    { path: '/sms/keywords/request', name: 'sms-keywords-request', component: KeywordRequest },

    // Premium
    {
        path: '/sms/premium/outbox', name: 'sms-premium-outbox', component: PremiumOutbox, meta: { subtitle: 'Premium Outbox' }
    },
    {
        path: '/sms/premium/subscriptions', name: 'sms-premium-subscriptions', component: PremiumSubscriptions, meta: { subtitle: 'Subscription Products' }
    },
    {
        path: '/sms/premium/analytics', name: 'sms-premium-analytics', component: PremiumAnalytics, meta: { subtitle: 'Premium Analytics' }
    },
    {
        path: '/sms/premium/callback', name: 'sms-premium-callback', component: PremiumCallback, meta: { subtitle: 'Subscription Callback' }
    },

    // Bulk
    {
        path: '/sms/bulk/callback', name: 'sms-bulk-callback', component: BulkCallback, meta: { subtitle: 'Bulk Callback' }
    },
    {
        path: '/sms/bulk/outbox', name: 'sms-bulk-outbox', component: BulkOutbox, meta: { subtitle: 'Bulk Outbox' }
    },
    {
        path: '/sms/bulk/analytics', name: 'sms-bulk-analytics', component: BulkAnalytics, meta: { subtitle: 'Bulk Analytics' }
    },

    // SMPP
    {
        path: '/sms/smpp/accounts', name: 'sms-smpp-accounts', component: SmppAccounts, meta: { subtitle: 'SMPP Accounts' }
    },

    // DLR
    {
        path: '/sms/dlr/callback', name: 'sms-dlr-callback', component: DlrCallback, meta: { subtitle: 'Delivery Reports Callback' }
    },

    // Sandbox...
    { path: '/sms/shortcodes/create', name: 'sms-shortcodes-create', component: SandboxShortcodeCreate },
    { path: '/sms/premium/create', name: 'sms-premium-create', component: SandboxPremiumCreate },
    { path: '/sms/alphanumerics/create', name: 'sms-alpha-create', component: SandboxAlphaCreate }

]

export default routes
