/* global Cookies */
import { jwtDecode } from 'jwt-decode'
import invoke, { COOKIES } from './invoke'

export default {
    endAlias: async (email) => {
        try {
            await invoke('POST', `/admin/auth/alias/${email}/revoke`, null, { noAlias: true })
            const user = jwtDecode(Cookies.get(COOKIES.TOKEN))
            return user
        } catch (ex) {
            return null
        }
    }
}
