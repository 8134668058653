import { isLoggedIn } from '../../utils/api/auth'
import { verifyUser, changeUserEmail, mergeUser } from '../../utils/api/user'
import { enableApp } from '../../utils/api/teams'

const Login = resolve => require(['./login/component.vue'], resolve); // eslint-disable-line
const Invite = resolve => require(['./login/invite.vue'], resolve); // eslint-disable-line
const Password = resolve => require(['./password/component.vue'], resolve); // eslint-disable-line
const PasswordChange = resolve => require(['./password/PasswordChange.vue'], resolve); // eslint-disable-line
const Email = resolve => require(['./email/component.vue'], resolve); // eslint-disable-line
const Merge = resolve => require(['./merge/component.vue'], resolve); // eslint-disable-line
const OAuthAuthorize = resolve => require(['./oauth/authorize/component.vue'], resolve); // eslint-disable-line
const OAuthCallback = resolve => require(['./oauth/callback/component.vue'], resolve); // eslint-disable-line

const loginBeforeEnter = (to, from, next) => {
    if (isLoggedIn()) {
        next('/')
        return
    }
    next()
}

const verifyBeforeEnter = async (to, from, next) => {
    window.nest.verificationCode = to.query.code

    if (isLoggedIn()) {
        const notification = {
            message: 'Invalid Verification Code',
            type: 'error',
            ttl: 5000
        }
        let success = false
        if (to.name === 'verify') {
            success = await verifyUser(window.nest.verificationCode)
            notification.message = success ? 'Your account has been successfully verified!' : 'Failed to verify your account'
        } else if (to.name === 'change-email') {
            success = await changeUserEmail(window.nest.verificationCode)
            notification.message = success ? 'Your email has been successfully changed!' : 'Failed to change your email'
        } else if (to.name === 'merge-user') {
            success = await mergeUser(window.nest.verificationCode)
            notification.message = success ? 'Your teams have been successfully merged!' : 'Failed to merge your teams'
        } else if (to.name === 'enable-app') {
            try {
                const app = JSON.parse(atob(to.query.params))
                success = await enableApp(app.team, app.id, null, window.nest.verificationCode)
                if (!success) {
                    if (window.nest.last_kmsg) {
                        notification.message = window.nest.last_kmsg
                        window.nest.last_kmsg = null
                    } else {
                        notification.message = `Failed to enable app <b>${app.name}</b>`
                    }
                } else {
                    notification.message = `The app <b>${app.name}</b> has been enabled!`
                }
            } catch (ex) {
                success = false
                notification.message = 'Failed to enable the app :('
            }
        }
        notification.type = success ? 'success' : 'error'
        window.nest.notification = notification
        return next('/')
    }

    let messagePrompt = ''
    if (to.name === 'verify') {
        messagePrompt = 'Login to complete email verification'
    } else if (to.name === 'change-email') {
        messagePrompt = 'Login with your old email to complete email change'
    } else if (to.name === 'merge-user') {
        messagePrompt = 'Login to complete account merge'
    } else if (to.name === 'register-invite') {
        if (to.query.params) {
            const invite = JSON.parse(atob(to.query.params))
            window.nest.teamInvite = invite
            messagePrompt = `Register to accept your invitation to team <b>${invite.teamName}</b>`
        } else {
            return next('/')
        }
    } else if (to.name === 'enable-app') {
        try {
            const app = JSON.parse(atob(to.query.params))
            messagePrompt = `Login to enable the app <b>${app.name}</b>`
        } catch (ex) {
            messagePrompt = 'Login to enable the app.'
        }
    }
    window.nest.notification = {
        message: `Hi There!&nbsp;${messagePrompt}`,
        messagePrompt,
        type: 'success',
        ttl: 5000
    }
    return next()
}

export default [
    {
        path: '/auth/login',
        name: 'login',
        component: Login,
        beforeEnter: loginBeforeEnter
    },

    {
        path: '/auth/password',
        name: 'password',
        component: Password
    },
    {
        path: '/auth/email',
        name: 'email',
        component: Email
    },
    {
        path: '/auth/email/change',
        name: 'change-email',
        component: Login,
        beforeEnter: verifyBeforeEnter
    },

    {
        path: '/auth/merge',
        name: 'merge-user',
        component: Login,
        beforeEnter: verifyBeforeEnter
    },

    {
        path: '/auth/verify',
        name: 'verify',
        component: Login,
        beforeEnter: verifyBeforeEnter
    },

    {
        path: '/auth/app/enable',
        name: 'enable-app',
        component: Login,
        beforeEnter: verifyBeforeEnter
    },

    {
        path: '/auth/merge/request',
        name: 'merge-user-request',
        component: Merge
    },

    {
        path: '/auth/password/change',
        name: 'password-reset',
        component: Password,
        meta: {
            subtitle: 'Reset Password'
        }
    },

    {
        path: '/auth/password/expired',
        name: 'password-expiry',
        component: PasswordChange,
        meta: {
            title: 'Change Password'
        }
    },

    {
        path: '/auth/register',
        name: 'register',
        component: Login,
        beforeEnter: loginBeforeEnter
    },

    {
        path: '/auth/register/invite',
        name: 'register-invite',
        component: Invite,
        beforeEnter: verifyBeforeEnter
    },

    {
        path: '/auth/authorize',
        name: 'oauth-authorize',
        component: OAuthAuthorize
    },

    {
        path: '/auth/callback',
        name: 'oauth-callback',
        component: OAuthCallback
    },

    {
        path: '/auth/forgot',
        name: 'forgot',
        component: Login,
        beforeEnter: loginBeforeEnter
    }
]
