import { getCurrentApp } from './index'
import admin from './api/admin'

export const ItemsPerPage = window.matchMedia('(max-width: 39.9375em)').matches ? 10 : 50

export const RouterMode = 'history' // or 'hash'

export const adminPrivilegeLevels = {
    SUPER_ADMIN: 12,
    INTERMEDIATE: 9,
    NORMAL: 1
}

export const ProgressBarOptions = {
    color: '#5cb85c',
    failedColor: '#e7202a',
    thickness: '3px',
    transition: {
        speed: '0.2s',
        opacity: '0.6s'
    }
}

export const getPaymentProviders = () => {
    const app = getCurrentApp()
    if (app && app.sandbox) {
        return [{ id: 'athena', name: 'Athena' }]
    }
    return [
        { id: 'mpesa', name: 'M-Pesa' },
        { id: 'segovia', name: 'Segovia' }, //
        { id: 'flutterwave', name: 'Flutterwave' },
        { id: 'admin', name: 'Admin' },
        { id: 'tigopesa', name: 'Tigopesa' },
        { id: 'telkomkenya', name: 'TelkomKenya' },
        { id: 'mtnuganda', name: 'MtnUganda' },
        { id: 'vodacomtanzania', name: 'VodacomTanzania' }
    ]
}

export const getAirtimeNetworks = async () => {
    const app = getCurrentApp()
    let networks = null
    if (app && app.sandbox) {
        networks = await admin.airtime.fetchNetworks({ sandbox: true })
        return networks
    }
    networks = await admin.airtime.fetchNetworks({ sandbox: false })
    return networks
}

export const configLogTypesAndStatuses = {
    types: [
        { id: 'all', name: 'All' },
        { id: 'network_routing', name: 'Network Routing' },
        { id: 'opt_out', name: 'Opt Out' },
        { id: 'corporate_bind', name: 'Corporate Bind' },
        { id: 'opt_in', name: 'Opt In' },
        { id: 'bank_bind', name: 'Bank Bind' },
        { id: 'international', name: 'International' }
    ],
    statuses: [
        { id: 'all', name: 'All' },
        { id: 'created', name: 'Created' },
        { id: 'deleted', name: 'Deleted' }
    ]
}

export const paymentLogTypes = [
    { id: 'all', name: 'All' },
    { id: 'mpesa', name: 'M-Pesa' },
    { id: 'cheque', name: 'Cheque' },
    { id: 'refund', name: 'Refund' },
    { id: 'wallet_topup', name: 'Wallet Top Up' },
    { id: 'wallet_refund', name: 'Wallet Refund' },
    { id: 'wallet_transaction_replay', name: 'Wallet Transaction Replay' }
]

export const serviceLogTypesAndStatuses = {
    types: [
        { id: 'all', name: 'All' },
        { id: 'short_code', name: 'Short Code' },
        { id: 'keyword', name: 'Keyword' },
        { id: 'alphanumeric', name: 'Alphanumeric' },
        { id: 'smpp', name: 'SMPP' },
        { id: 'product', name: 'Product' },
        { id: 'ussd', name: 'USSD' },
        { id: 'mobile_payment', name: 'Mobile Payment' },
        { id: 'chat_product', name: 'Chat Product' },
        { id: 'chat_product_channel', name: 'Chat Product Channel' },
        { id: 'voice_phone', name: 'Voice Phone' },
        { id: 'app_username', name: 'App Username' },
        { id: 'airtime', name: 'Airtime' },
        { id: 'sms_billing_override', name: 'SMS Billing Override' },
        { id: 'ussd_billing_override', name: 'USSD Billing Override' }
    ],
    statuses: [
        { id: 'all', name: 'All' },
        { id: 'created', name: 'Created' },
        { id: 'updated', name: 'Updated' },
        { id: 'deleted', name: 'Deleted' }
    ]
}

export const smsOverrideTypes = [

    {
        type: 'OUTGOING',
        value: 1
    },
    {
        type: 'INCOMING',
        value: 0
    }

]

export const chatBillingPlans = [
    {
        type: 'Starter',
        value: 1
    },
    {
        type: 'Basic',
        value: 2
    },
    {
        type: 'Plus',
        value: 3
    },
    {
        type: 'Premium',
        value: 4
    },
    {
        type: 'Max',
        value: 3
    }
]

export const chatChannelTypes = [
    {
        type: 'Google RCS',
        value: 1,
        enabled: false
    },
    {
        type: 'Facebook Messenger',
        value: 2,
        enabled: false
    },
    {
        type: 'Telegram',
        value: 3,
        enabled: false
    },
    {
        type: 'WhatsApp',
        value: 4,
        enabled: true
    }
]
