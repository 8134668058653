import invoke from './invoke'
import { makeAppPath } from '../index'
import { trimSpaces } from '../filters'

export async function getBalance () {
    try {
        return await invoke('GET', makeAppPath('/balance'))
    } catch (ex) {
        return false
    }
}

export async function getBalanceSettings () {
    try {
        return await invoke('GET', makeAppPath('/balance/settings'))
    } catch (ex) {
        return false
    }
}

// eslint-disable-next-line
export async function updateBalanceSettings(disable, threshold, additionalEmails, enableSms, additionalNumbers) {
    try {
        const additionalSmsNumbers = additionalNumbers.map((number) => number.toString())
        const params = {
            disable,
            threshold: parseInt(threshold, 10) || 1,
            additionalEmails,
            enableSms,
            ...(enableSms === true && { additionalSmsNumbers })
        }
        await invoke('POST', makeAppPath('/balance/settings'), trimSpaces(params))
        return true
    } catch (ex) {
        return false
    }
}

export async function generateApiKeyLink (password) {
    try {
        await invoke('POST', makeAppPath('/apiKey/generate'), { password })
        return true
    } catch (ex) {
        return false
    }
}

export async function generateAPIKey (code) {
    try {
        return await invoke('POST', makeAppPath('/apiKey'), { code })
    } catch (ex) {
        return null
    }
}

export async function deleteNotificationEmail (email) {
    try {
        await invoke('DELETE', makeAppPath(`/balance/settings/email?email=${email}`))
        return true
    } catch (ex) {
        return false
    }
}

export async function fetchChartsConfig () {
    try {
        return await invoke('GET', makeAppPath('/config/charts'))
    } catch (ex) {
        return false
    }
}

export async function configureCharts (configs) {
    try {
        return await invoke('POST', makeAppPath('/config/charts'), configs)
    } catch (ex) {
        return null
    }
}
