/* global VanillaModal, Lockr */
/* eslint-disable */
import countries from './detailedCountries';

Array.prototype.unique = function () {
    return this.filter((v, idx, self) => self.indexOf(v) === idx);
};

Array.prototype.sortBy = function (key) {
    return this.sort((a, b) => {
            let first = a[key];
            let second = b[key];
            if (typeof first === 'string') {
                first = first.toLowerCase();
                second = second.toLowerCase(); // assumes first and second are of the same type
            }
            if (first > second) {
                return 1;
            }
            if (first < second) {
                return -1;
            }
            return 0;
        });
    }

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, argument) {
        argument = argument || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(argument, this[i], i, this);
        }
    };
}
String.prototype.stripTags = function () {
    return this.replace(/(<([^>]+)>)/ig, '');
}
String.prototype.encodeTags = function () {
    return this.replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
}
String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}
export const isValidEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
export function isValidPhoneNumber(phoneNumber, intlFormat = false) {
    const phoneFormatRegex = /^(\+[1-9]{1,3})?\d{4,}$/;
    const phoneIntlFormatRegex = /^\+[1-9]{1,3}\d{4,}$/;

    return intlFormat
        ? phoneIntlFormatRegex.test(phoneNumber)
        : phoneFormatRegex.test(phoneNumber);
}
/* eslint-enable */

export const Modal = VanillaModal.default

export const isSmallestViewport = window.matchMedia('(max-width: 39.9375em)').matches

export const loadCss = (path) => {
    const link = document.createElement('link')
    link.setAttribute('rel', 'stylesheet')
    link.setAttribute('type', 'text/css')
    link.setAttribute('href', path)
    document.getElementsByTagName('head')[0].appendChild(link)
}

export const truncate = (text, max = 280, padText = '...') => {
    if (text && text.length > max) {
        return `${text.substring(0, max)}${padText}`
    }
    return text
}

export const getCurrentAppId = () => {
    const parts = window.location.pathname.split('/')
    if (parts[1] === 'apps') {
        return parts[2]
    }
    return null
}

export const getCurrentApp = () => {
    const appId = getCurrentAppId()

    if (appId === 'sandbox') {
        // FIXME: Get user country
        return {
            id: 'sandbox', name: 'Sandbox', username: 'sandbox', sandbox: true, country: { currency: 'KES' }
        }
    }

    const teams = Lockr.get('teams')
    if (teams) {
        const team = teams.find((item) => item.apps.find((app) => app.id === appId))
        if (!team) {
            return null
        }
        const app = team.apps.find((item) => item.id === appId)
        return app
    }
    return null
}

export const getTeam = (teamId) => {
    const teams = Lockr.get('teams')
    if (teams) {
        return teams.find((item) => item.id === teamId)
    }
    return null
}

export const getCurrentTeam = () => {
    const appId = getCurrentAppId()

    if (appId === 'sandbox') {
        return null
    }

    const teams = Lockr.get('teams')
    if (teams) {
        return teams.find((item) => item.apps.find((app) => app.id === appId))
    }
    return null
}

export const makeAppPath = (path) => {
    if (!getCurrentApp()) {
        throw new Error('Invalid app')
    }
    return `/apps/${getCurrentAppId()}${path}`
}

export const sanitizeUrl = (url) => {
    const ctrlCharactersRegex = /[^\x20-\x7E]/gmi
    const urlSchemeRegex = /^([^:]+):/gm
    const relativeFirstCharacters = ['.', '/']

    const isRelativeUrlWithoutProtocol = (entry) => relativeFirstCharacters.indexOf(entry[0]) > -1

    if (!url) { return '/' }

    const result = url.replace(ctrlCharactersRegex, '').trim()
    if (isRelativeUrlWithoutProtocol(result)) {
        return result
    }
    const urlSchemeParseResults = result.match(urlSchemeRegex)
    if (!urlSchemeParseResults) {
        return result
    }
    return '/'
}

export const getLoginNextUrl = (route) => {
    if (!route.query.next) return '/'
    let url = sanitizeUrl(route.query.next)
    const queries = Object.keys(route.query)
    if (queries.length > 1) {
        url += '?'
        for (let i = 0; i < queries.length; i++) { // eslint-disable-line
            const q = queries[i]
            if (q !== 'next') {
                url += `${q}=${route.query[q]}`
                if (i < queries.length - 1) {
                    url += '&'
                }
            }
        }
    }
    return url
}

export const isEmpty = (val) => {
    if (val === null || val === undefined) {
        return true
    } if (typeof val === 'string') {
        return !val.trim().length
    } if (typeof val === 'number') {
        return false
    } if (Array.isArray(val)) {
        return val.length === 0
    }
    return false
}

export const capitalize = (value) => {
    if (value) {
        return value.charAt(0).toUpperCase() + value.slice(1)
    }
    return 'N/A'
}

export const money = (amount, decimalPoints = 2) => { // eslint-disable-line
    const abs = Math.abs(amount).toFixed(decimalPoints).replace(/./g, (c, i, a) => { // eslint-disable-line
        return i && c !== '.' && ((a.length - i) % 3 === 0) ? `,${c}` : c
    })

    return amount < 0 ? `-${abs}` : abs
}

export const encodeXml = (xml) => {
    const map = {
        '&': '&amp;',
        '"': '&quot;',
        '<': '&lt;',
        '>': '&gt;'
    }
    return xml.replace(/([&"<>])/g, (str, item) => map[item])
}

export const showNotification = (message, type = 'info', ttl = 4500, delay = 400) => {
    const notif = new Notification({
        message,
        type,
        ttl
    })
    setTimeout(notif.show, delay)
}

export const notificationWatcher = (key, type, ttl = 4500, delay = 400) => function fx (message) {
    if (this[key] === null) {
        return
    }
    this[key] = null
    showNotification(message, type, ttl, delay)
}

export const showConfirmNotification = (
    message,
    onConfirm,
    type = 'error',
    ttl = 5000,
    options = { positive: 'Yes', negative: 'No' }
) => {
    let positiveCallback = onConfirm
    let negativeCallback = () => false
    if (typeof onConfirm === 'object') {
        positiveCallback = onConfirm.positive
        negativeCallback = onConfirm.negative
    }

    const notificationOptions = {
        message,
        type,
        ttl,
        actions: {
            positive: {
                btnText: options.positive,
                callback: positiveCallback
            },
            negative: {
                btnText: options.negative,
                callback: negativeCallback
            }
        }
    }

    const notif = new Notification(notificationOptions)
    setTimeout(() => {
        notif.show()
    }, 50)
}

export const dateUtil = {
    makeDateRangeQuery: (query) => {
        const result = { ...query }
        const dateRange = query.dateRange ? query.dateRange.split(' to ') : []
        if (dateRange.length === 2) {
            const [startDate, endDate] = dateRange
            result.startDate = startDate
            result.endDate = endDate
            delete result.dateRange
        }
        return result
    },

    getShortDay: (date) => ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()],

    getShortMonth: (date) => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][date.getMonth()],

    isoFormat: (date = new Date()) => {
        let month = date.getMonth() + 1
        if (month < 10) {
            month = `0${month}`
        }
        let day = date.getDate()
        if (day < 10) {
            day = `0${day}`
        }
        return `${date.getFullYear()}-${month}-${day}`
    },

    displayFormat: (date = new Date(), year = true) => {
        // (Tue) 28 Feb [year]
        let day = date.getDate()
        if (day < 10) {
            day = `0${day}`
        }
        return `${dateUtil.getShortDay(date)} ${day} ${dateUtil.getShortMonth(date)} ${year ? date.getFullYear() : ''}`.trim()
    }
}

export const analyticsUtil = {
    getChartHeight: () => {
        const chartDiv = document.querySelector('.product-page-chart-body')
        let height = 300
        let padding = 15
        if (chartDiv) {
            const chartStyles = window.getComputedStyle(chartDiv, null)
            height = parseInt(chartStyles.getPropertyValue('height'), 10) || 300
            padding = parseInt(chartStyles.getPropertyValue('padding'), 10) || 15
        }
        return `${height - (padding * 2)}`
    },
    colors: {
        list: ['#f18114', '#2c963f', '#e7202a', '#db20e7', '#8820e7', '#20e7de', '#63e720', '#f1ea14', '#504c4c', '#9e5e53'],
        random: () => analyticsUtil.colors.list[
            Math.floor(Math.random() * analyticsUtil.colors.list.length)
        ],
        randomWithMax: (max) => {
            max = max || 1; // eslint-disable-line
            max = max > analyticsUtil.colors.list.length ? analyticsUtil.colors.list.length : max; // eslint-disable-line
            const picked = []
            const generateColors = () => {
                const color = analyticsUtil.colors.random()
                if (picked.includes(color)) {
                    return generateColors()
                }
                picked.push(color)
                if (picked.length === max) {
                    return picked
                }
                return generateColors()
            }
            generateColors()
            return picked
        },
        failed: '#e7202a',
        success: '#2c963f',
        total: '#343434',
        bonus: '#f18114'
    },

    makeDateRange: (startDate, endDate) => {
        const dateRange = []
        let start = startDate
        while (start <= endDate) {
            dateRange.push(dateUtil.displayFormat(new Date(start), false))
            start += 24 * 60 * 60 * 1000
        }
        return dateRange
    },

    makeGraphData: (data, dateRange, key = undefined) => {
        if (!dateRange) return []
        if (!data) return Array(dateRange.length).fill(0)
        const result = Array(dateRange.length).fill(0)
        const dates = (key) ? Object.keys(data[key] || []) : Object.keys(data)
        dates.forEach((date) => {
            const idx = dateRange.indexOf(dateUtil.displayFormat(new Date(date), false))
            if (idx !== -1) {
                result[idx] = (key) ? data[key][date] : data[date]
            }
        })
        // replace nulls with 0
        return result.map((item) => {
            if (item !== null && item !== undefined) return item
            return 0
        })
    },

    makeGraphDataFromElementsArray: (data, dateRange, key = undefined) => {
        if (!dateRange) return []
        if (!data) return Array(dateRange.length).fill(0)
        const result = Array(dateRange.length).fill(0)
        const dates = data.map((i) => i.date)
        dates.forEach((date) => {
            const idx = dateRange.indexOf(dateUtil.displayFormat(new Date(date), false))
            if (idx !== -1) {
                const target = data.find((i) => i.date === date)
                result[idx] = (key) ? parseInt(target.elements[key], 10) : 0
            }
        })
        // replace nulls with 0
        return result.map((item) => {
            if (item !== null && item !== undefined) return item
            return 0
        })
    },

    formatNumber: (number) => {
        let numberStr = ''
        let num = number
        let no = num.substr(-3)
        while (no.length > 0) {
            num = num.substr(0, num.length - 3)
            numberStr = `${no},${numberStr}`
            no = num.substr(-3)
        }
        return numberStr.substr(0, numberStr.length - 1)
    },

    zeroReplace: (data) => Object.values(data || []).map((item) => {
        if (!item) { return 0 }
        return item
    }),

    zeroFill: (startDate, endDate) => {
        if (typeof startDate === 'string') {
            startDate = (new Date(startDate)).getTime(); // eslint-disable-line
            endDate = (new Date(endDate)).getTime(); // eslint-disable-line
        }
        const dateRange = []
        const fill = []
        const result = {
            success: false
        }
        let start = startDate
        if (start <= endDate) {
            result.validDate = true
            while (start <= endDate) {
                const date = new Date(start)
                dateRange.push(dateUtil.displayFormat(date, false))
                fill.push(0)
                start = date.getTime() + (24 * 60 * 60 * 1000)
            }
            result.dateRange = dateRange
            result.zeroFill = {
                name: 'Total',
                data: fill,
                color: analyticsUtil.colors.bonus
            }
        } else {
            result.validDate = false
        }
        return result
    },

    sumDatesTotals: (data) => {
        const keys = Object.keys(data)
        let total = 0
        keys.forEach((k) => {
            total += data[k]
        })
        return total
    }
}

export const isMobile = () => {
    const ua = navigator.userAgent
    return ua.match(/Android/i) || ua.match(/iPhone|iPad|iPod/i) || ua.match(/Opera Mini/i) || ua.match(/BlackBerry/i)
}

export const getDomain = () => window.location.hostname.replace(/^.+?\./g, '') // removes account. from account.africastalking.com

export const messageMetadata = (message) => {
    if (message === '__redacted__') { return '' }
    const { length } = message
    const characterNoun = length > 1 ? 'characters' : 'character'
    return `(${length} ${characterNoun})`
}

export const formatConfigType = (configType) => {
    const newConfig = configType.replace('_', ' ').toLowerCase().capitalize()
    return newConfig
}

export const formatTelcoName = (code) => {
    let network
    if (code === 'All Tanzania' ||
        code === 'South Africa' ||
        code === 'Ethiopia' ||
        code === 'Safaricom New SDP'
    ) {
        network = {
            code,
            name: code
        }
    } else {
        const country = countries.find((c) => code.match(new RegExp(`^${c.mcc}`)) !== null)
        const telco = country.providers.find((tel) => String(code) === String(tel.code))
        network = {
            code: telco.code,
            name: `${telco.name} ${country.name}`
        }
    }
    return network
}

export const formatErrorMsg = (message, delimiter = null) => {
    let errResponse = ''
    if (typeof message === 'object') {
        message.forEach((err) => {
            let errorMsg = err.message
            errorMsg = delimiter ? errorMsg.split(delimiter)[0] : errorMsg
            errResponse += ` ${errorMsg}`
        })
    } else {
        errResponse = message
    }
    return errResponse
}

export const debounce = (func, delay = 650) => {
    let timer
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => func(...args), delay)
    }
}

export const formatOverrideType = (type) => {
    const override = (type === 0) ? 'Incoming' : 'Outgoing'
    return override
}
