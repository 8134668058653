/* global Cookies, nest */
import 'whatwg-fetch'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'

import * as rules from 'vee-validate/dist/rules'
import messages from 'vee-validate/dist/locale/en.json'
/* Just to avoid including multiple times */
import VuePassword from 'vue-password'; // eslint-disable-line
import appLoading from 'app-loading'
import VTooltip from 'v-tooltip'

import CookieConsent from './components/CookiesConsent.vue'

/* eslint-disable */
import appLoadingCss from 'app-loading/app-loading.min.css'
import foundationCss from '../assets/css/foundation.css';
/* eslint-enable */

import { showNotification, getCurrentApp } from './utils'
import { ProgressBarOptions, RouterMode } from './utils/config'
import {
    isLoggedIn,
    isAliasing,
    getUser,
    isPasswordExpiry
} from './utils/api/auth'
import { COOKIES } from './utils/api/invoke'

import views from './views'
import Header from './components/header/component.vue'
import Banner from './components/banner.vue'
import Footer from './components/footer/component.vue'
import SideBar from './components/sidebar/component.vue'
import NavMenu from './components/navigation/nav.vue'
import expiredSession from './utils/ui/expiredSession'

// popup messages
window.addEventListener('message', (evt) => {
    if (!nest.login.allowedOrigins.includes(evt.origin)) {
        return
    }
    switch (evt.data) {
    case nest.login.requests.RQ_LOGIN:
        window.loo = evt
        evt.source.postMessage(nest.login.requests.RQ_LOGIN_ACK, evt.origin)
        break
    default:
        break
    }
}, false)
// ---

/* veevalidate */
Object.keys(rules).forEach((rule) => {
    extend(rule, {
        ...rules[rule],
        message: messages[rule]
    })
})

// https://github.com/logaretm/vee-validate/issues/922
// eslint-disable-next-line no-unused-vars
function validateCheckboxes (value, _args) {
    const single = typeof value === 'boolean'
    const valid = single
        ? value
        : Object.keys(value).every((key) => !!value[key])

    return { valid, data: value }
}

extend('checked', {
    validate: validateCheckboxes,
    getMessage (field, params, value) {
        return typeof value === 'boolean'
            ? 'This field needs to be checked'
            : 'All fields need to be checked'
    }
})
/* end veevalidate */

const initializeApp = () => {
    Vue.use(VueRouter)
    window.appLoading = appLoading
    appLoading.setColor(ProgressBarOptions.color)

    const router = new VueRouter({ routes: views, mode: RouterMode })

    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)

    Vue.use(VTooltip)

    nest.vm = new Vue({ // eslint-disable-line no-new
        el: '#root',
        router,
        data: () => ({
            loggedIn: isLoggedIn() || isLoggedIn(true),
            isSandbox: false,
            isStaging: false,
            isUserAuthPage: false,
            isPasswordExpiry: false,
            analyticsEnabled: (() => {
                if (isAliasing()) return false
                return true
            })(),
            isNotificationOpen: false,
            notificationBannerContent: nest.notificationBanner.content,
            nav: {
                title: null,
                subtitle: null,
                fake: true
            },
            loaded: false
        }),
        components: {
            'at-header': Header,
            'at-banner': Banner,
            'at-sidebar': SideBar,
            'at-nav': NavMenu,
            'at-footer': Footer,
            'at-cookie-consent': CookieConsent
        },
        watch: {
            $route: 'updateNav'
        },

        beforeCreate () {
            const currentApp = getCurrentApp()
            this.isSandbox = currentApp && currentApp.sandbox
            this.isStaging = process.env.STAGING
            this.loggedIn = isLoggedIn() || isLoggedIn(true)
            if (this.isSandbox && this.loggedIn) {
                require('../assets/css/src-sandbox.css'); // eslint-disable-line
            } else {
                require('../assets/css/src.css'); // eslint-disable-line
            }
        },

        created () {
            // Analytics
            if (this.analyticsEnabled) {
                require('../assets/js/analytics/intercom'); // eslint-disable-line
                require('../assets/js/analytics/umami')(); // eslint-disable-line
            }
            if (nest.notificationBanner.active) {
                this.isNotificationBanner()
            }

            this.isUserAuthPage = !isLoggedIn() && !isLoggedIn(true)
            this.isPasswordExpiry = isPasswordExpiry()

            this.$router.beforeEach((to, from, next) => {
                appLoading.start()
                next()
            })

            this.$router.afterEach(() => {
                appLoading.stop()

                const notif = window.nest.notification
                if (notif && !notif.discarded) {
                    if (window.nest.notification.message) {
                        showNotification(notif.message, notif.type, notif.ttl || 4500)
                    }
                    window.nest.notification.discarded = true
                }

                if (this.analyticsEnabled) {
                    try {
                        if (this.loggedIn && window.intercomBootComplete) {
                            const user = getUser()
                            window.Intercom('update', { email: user.email })
                        }
                    } catch (ex) { /* ignore */ }
                }
            })
        },

        mounted () {
            if (this.analyticsEnabled && this.loggedIn && !window.intercomBootComplete) {
                const user = getUser()

                const params = {
                    user_id: user.id,
                    app_id: nest.analytics.intercom.appId,
                    name: `${user.firstName} ${user.lastName}`,
                    email: user.email,
                    created_at: user.created_at
                }
                if (user.intercom) { params.user_hash = user.intercom.user_hash }
                window.Intercom('boot', params)
                window.intercomBootComplete = true
            }
        },

        methods: {
            updateNav: function fx () {
                const route = this.$route.name

                this.nav.subtitle = this.$route.meta.subtitle

                if (/admin-teams/.test(route)) {
                    this.nav.title = 'Teams'
                } else if (/teams/.test(route)) {
                    this.nav.title = null
                } else if (/team/.test(route)) {
                    this.nav.title = null
                } else if (/dashboard/.test(route)) {
                    const app = getCurrentApp()
                    this.nav.title = app.name
                    this.nav.subtitle = app.username
                } else if (/ussd/.test(route)) {
                    this.nav.title = 'USSD'
                } else if (/sms/.test(route)) {
                    this.nav.title = 'SMS'
                } else if (/airtime/.test(route)) {
                    this.nav.title = 'Airtime'
                } else if (/insights/.test(route)) {
                    this.nav.title = 'Insights'
                } else if (/voice/.test(route)) {
                    this.nav.title = 'Voice'
                } else if (/mobile-data-/.test(route)) {
                    this.nav.title = 'Mobile Data'
                } else if (/payments/.test(route)) {
                    this.nav.title = 'Payments'
                } else if (/admin-home/.test(route)) {
                    this.nav.title = 'Africa\'s Talking'
                } else if (/logs-/.test(route)) {
                    this.nav.title = 'Logs'
                } else if (/admin-users/.test(route)) {
                    this.nav.title = 'Users'
                } else if (/contact/.test(route)) {
                    this.nav.title = 'Contact Us'
                } else if (/billing/.test(route)) {
                    this.nav.title = 'Billing'
                } else if (/settings/.test(route)) {
                    this.nav.title = 'Settings'
                } else if (/admin-wallet/.test(route) || /admin-api/.test(route)) {
                    this.nav.title = 'Administration'
                } else {
                    this.nav.title = this.$route.meta.title
                }
            },
            isNotificationBanner: function fx () {
                const cookie = Cookies.get(COOKIES.NOTIFBANNER)
                if (cookie && cookie !== 'closed' && this.loggedIn) {
                    this.isNotificationOpen = true
                }
            },
            closeNotificationBanner: function fx () {
                this.isNotificationOpen = false
                Cookies.set(COOKIES.NOTIFBANNER, 'closed')
            }
        }
    })

    // Global events listeners
    nest.vm.$once(nest.events.sessionExpired, () => expiredSession())
}

initializeApp()
