/* eslint-disable import/no-dynamic-require, global-require */
const ListCodes = (resolve) => require(['./codes/component.vue'], resolve)
const Analytics = (resolve) => require(['./analytics/component.vue'], resolve)
const Sessions = (resolve) => require(['./sessions/component.vue'], resolve)
const Request = (resolve) => require(['./request/component.vue'], resolve)
const SandboxUssdCreate = (resolve) => require(['./sandbox/create/component.vue'], resolve)
/* eslint-enable import/no-dynamic-require, global-require */

const routes = [
    {
        path: '/ussd/codes', name: 'ussd-codes', component: ListCodes, meta: { subtitle: 'Service Codes' }
    },
    { path: '/ussd/request', name: 'ussd-request', component: Request },
    {
        path: '/ussd/sessions', name: 'ussd-sessions', component: Sessions, meta: { subtitle: 'Sessions' }
    },
    {
        path: '/ussd/analytics', name: 'ussd-analytics', component: Analytics, meta: { subtitle: 'Analytics' }
    },

    // Sandbox
    { path: '/ussd/channel/create', name: 'sandbox-ussd-create', component: SandboxUssdCreate }
]

export default routes
