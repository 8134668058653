const Transactions = resolve => require(['./transactions/component.vue'], resolve); // eslint-disable-line
const StatusCallback = resolve => require(['./callbacks/status/component.vue'], resolve); // eslint-disable-line
const ValidationCallback = resolve => require(['./callbacks/validation/component.vue'], resolve); // eslint-disable-line
const Analytics = resolve => require(['./analytics/component.vue'], resolve); // eslint-disable-line
const RequestAirtime = resolve => require(['./request/component.vue'], resolve); // eslint-disable-line

export default [
    {
        path: '/airtime/transactions', name: 'airtime-transactions', component: Transactions, meta: { subtitle: 'Transactions' }
    },
    {
        path: '/airtime/callback/status', name: 'airtime-callback', component: StatusCallback, meta: { subtitle: 'Status Callback' }
    },
    {
        path: '/airtime/callback/validate', name: 'airtime-validation-callback', component: ValidationCallback, meta: { subtitle: 'Validation Callback' }
    },
    {
        path: '/airtime/analytics', name: 'airtime-analytics', component: Analytics, meta: { subtitle: 'Analytics' }
    },
    {
        path: '/airtime/request', name: 'airtime-request', component: RequestAirtime
    }
]
