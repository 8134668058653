import teams from './teams'
import account from './account'
import connections from './connections'

const routes = [].concat(
    teams,
    account,
    connections
)

export default routes
