/* global Cookies, Lockr, gravatar, moment, nest */
import { jwtDecode } from 'jwt-decode'
import invoke, { COOKIES } from './invoke'
import { showNotification, getCurrentApp, getDomain } from '..'
import { adminPrivilegeLevels } from '../config'

const profiles = {
    cache: {},
    make: (email = null) => {
        if (!email) return '/assets/img/default-avatar.png'
        if (!profiles.cache[email]) {
            profiles.cache[email] = gravatar(email, {
                secure: true,
                backup: `${nest.baseUrl}/assets/img/default-avatar.png`
            })
        }
        return profiles.cache[email]
    }
}

export const isAliasing = () => Cookies.get(COOKIES.ADMIN_ALIAS) !== undefined

export const getAliasingUser = () => {
    if (!isAliasing()) { return null }
    try {
        return jwtDecode(Cookies.get(COOKIES.TOKEN))
    } catch (ex) { return {} }
}

export const showAliasNotification = (user) => {
    if (!isAliasing() || Lockr.get('admin.alias.showNotification') === undefined) return
    showNotification(`You are logged in as <b>${user.email}</b>`, 'success')
    Lockr.rm('admin.alias.showNotification')
}

export function getUser (options = { photo: false }) {
    const token = isAliasing() ? Cookies.get(COOKIES.ADMIN_ALIAS) : Cookies.get(COOKIES.TOKEN)
    if (!token) {
        return { username: 'Guest' }
    }
    const user = jwtDecode(token)
    if (user && options.photo) {
        user.photo = profiles.make(user.email)
    }
    return user
}

export const isReadOnly = () => {
    const app = getCurrentApp()
    if (app && !app.sandbox) {
        const user = getUser()
        const team = (Lockr.get('teams') || []).find((acc) => acc.apps.find((item) => item.id === app.id))
        return team.members.find((m) => m.id === user.id).accessLevel === 'READ'
    }
    return false
}

export const isTeamAdmin = () => {
    const app = getCurrentApp()
    if (app) {
        if (app.sandbox) return true
        const user = getUser()
        const team = (Lockr.get('teams') || []).find((acc) => acc.apps.find((item) => item.id === app.id))
        const { accessLevel } = team.members.find((m) => m.id === user.id)
        return accessLevel === 'OWNER' || accessLevel === 'ADMIN'
    }
    return false
}

export const appOwner = () => {
    const app = getCurrentApp()
    if (app) {
        if (app.sandbox) return getUser()
        const team = (Lockr.get('teams') || []).find((acc) => acc.apps.find((item) => item.id === app.id))
        const user = team.members.find((m) => m.accessLevel === 'OWNER')
        return user
    }
    return null
}

export function isLoggedIn (admin = undefined) {
    try {
        let user = null
        if (isAliasing()) {
            user = jwtDecode(Cookies.get(COOKIES.ADMIN_ALIAS))
        } else {
            user = jwtDecode(Cookies.get(COOKIES.TOKEN))
        }
        return (admin === true ? user.role === 'admin' : user.role === 'normal')
    } catch (ex) {
        return false
    }
}

export function isProductRequestSupported () {
    const { country: { name: appCountry } } = getCurrentApp()

    const { whitelistedCountries } = nest.productRequests

    const isCountryWhitelisted = whitelistedCountries
        .map((country) => country.toLowerCase())
        .includes(appCountry.toLowerCase())

    return isCountryWhitelisted
}

export function isAdmin () {
    return isLoggedIn(true)
}

export function isSuperAdmin () {
    if (!isAdmin()) {
        return false
    }
    return getUser().privilegeLevel === adminPrivilegeLevels.SUPER_ADMIN
}

export function isNormalAdmin () {
    if (!isAdmin()) {
        return false
    }
    return getUser().privilegeLevel === adminPrivilegeLevels.NORMAL
}

export function isIntermediateAdmin () {
    if (!isAdmin()) {
        return false
    }
    return getUser().privilegeLevel === adminPrivilegeLevels.INTERMEDIATE
}

export function ensureLoggedIn (to, from, next) {
    if (!isLoggedIn()) {
        return next({ name: 'login', query: { next: to.path, ...to.query } })
    }
    return next()
}

export function isPasswordExpiry () {
    return window.location.pathname === '/auth/password/expired'
}

export function ensureLoggedInAndProductRequestsAreSupported (to, from, next) {
    if (!isLoggedIn()) {
        return next({ name: 'login', query: { next: to.path, ...to.query } })
    }
    if (!isProductRequestSupported()) {
        return next({ name: 'dashboard' })
    }
    return next()
}

export function ensureAdminLoggedIn (to, from, next) {
    if (isLoggedIn()) { // if logged in as normal, oops
        return next('/oops')
    }

    if (!isLoggedIn(true)) {
        return next({ name: 'admin-login', query: { next: to.path, ...to.query } })
    }
    return next()
}

export function ensureIntermediateAdminLoggedIn (to, from, next) {
    if (isLoggedIn()) { // if logged in as normal, oops
        return next('/oops')
    }

    if (!isLoggedIn(true)) {
        return next({ name: 'admin-login', query: { next: to.path, ...to.query } })
    }

    if (!isIntermediateAdmin() && !isSuperAdmin()) {
        return next({ name: 'admin-home' })
    }
    return next()
}

export function ensureSuperAdminLoggedIn (to, from, next) {
    if (isLoggedIn()) { // if logged in as normal, oops
        return next('/oops')
    }

    if (!isLoggedIn(true)) {
        return next({ name: 'admin-login', query: { next: to.path, ...to.query } })
    }

    if (!isSuperAdmin()) {
        return next({ name: 'admin-home' })
    }
    return next()
}

export async function sendPasswordReset (email, adminReset = false) {
    const data = {
        isAdmin: adminReset,
        email,
        appUri: `${nest.baseUrl}/auth/password/change` // not used anymore, left as an attempt to mislead the curious ones lol
    }

    try {
        await invoke('POST', '/auth/password/reset', data, { noAuth: true })
        return true
    } catch (ex) {
        return false
    }
}

export async function cleanUpSession () {
    // clean up
    Lockr.flush()
    Cookies.remove(COOKIES.TOKEN, { domain: getDomain() })
    Cookies.remove(COOKIES.ADMIN_ALIAS)
}

export async function signIn ({
    email,
    captcha,
    password,
    service = undefined,
    authorizationCode = undefined,
    admin = undefined,
    relogin = false
} = {}) {
    try {
        if (!relogin) {
            await cleanUpSession()
        }

        let data
        if (authorizationCode && service) {
            data = { authorizationCode, service }
        } else {
            data = { email, password, timeOffset: moment().utcOffset() }
            if (admin) {
                data.admin = true
                data.username = email
            }
        }
        data.captcha = captcha
        data.relogin = relogin
        const response = await invoke('POST', '/auth/signin', data, { noAuth: true })
        if (response.user) {
            return response.user
        }

        const user = jwtDecode(response.access_token)

        Cookies.set(COOKIES.TOKEN, response.access_token, {
            expires: COOKIES.getExpirationTime(),
            domain: getDomain(),
            secure: true,
            sameSite: 'Strict'
        })
        if (nest.notificationBanner.active && Cookies.get(COOKIES.NOTIFBANNER) !== 'closed') {
            Cookies.set(COOKIES.NOTIFBANNER, true, {
                expires: COOKIES.getExpirationTime(),
                secure: true,
                sameSite: 'Strict'
            })
        }
        return user
    } catch (ex) {
        return null
    }
}

export async function resendOtp ({ user, strategy }) {
    try {
        const result = await invoke('POST', '/auth/signin/otp/resend', { user, strategy }, { noAuth: true })
        return result
    } catch (ex) {
        return null
    }
}

export async function validateOtp ({ user, code }) {
    try {
        const data = {
            user,
            code
        }
        const response = await invoke('POST', '/auth/signin/otp/validate', data, { noAuth: true })

        const userObj = jwtDecode(response.access_token)
        Cookies.set(COOKIES.TOKEN, response.access_token, {
            expires: COOKIES.getExpirationTime(),
            domain: getDomain(),
            secure: true,
            sameSite: 'Strict'
        })
        if (nest.notificationBanner.active && Cookies.get(COOKIES.NOTIFBANNER) !== 'closed') {
            Cookies.set(COOKIES.NOTIFBANNER, true, {
                expires: COOKIES.getExpirationTime()
            })
        }
        return {
            userObj,
            token: response.access_token,
            passwordRefresh: response.password_refresh,
            clientId: response.client_id
        }
    } catch (ex) {
        return null
    }
}

export async function signUp ({
    password,
    email,
    countryCode,
    firstName,
    lastName,
    captcha,
    service = undefined,
    authorizationCode = undefined,
    inviteCode = undefined
} = {}) {
    try {
        await cleanUpSession()

        let data = {}
        if (authorizationCode) {
            data = {
                captcha,
                service,
                countryCode,
                authorizationCode,
                appUri: nest.baseUrl,
                userVerificationUri: `${nest.baseUrl}/auth/verify`, // ?code=ABSGD
                password
            }
        } else {
            data = {
                captcha,
                email,
                firstName,
                lastName,
                password,
                tosAgreed: true,
                countryCode,
                appUri: nest.baseUrl,
                userVerificationUri: `${nest.baseUrl}/auth/verify`, // ?code=ABSGD
                inviteCode
            }
        }

        const response = await invoke('POST', '/auth/signup', data, { noAuth: true })
        const user = jwtDecode(response.access_token)
        Cookies.set(COOKIES.TOKEN, response.access_token, {
            expires: COOKIES.getExpirationTime(),
            domain: getDomain(),
            secure: true,
            sameSite: 'Strict'
        })
        return user
    } catch (ex) {
        return null
    }
}

export async function signOut () {
    try {
        await invoke('POST', '/auth/signout')
        await cleanUpSession()
        window.Intercom('shutdown')
        return true
    } catch (ex) {
        Lockr.flush() // HUH: soooo this can't throw?? :)
        return false
    }
}
export async function authorizeClient (query) {
    try {
        let url = '/auth/authorize'
        const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?')
        url = `${url}${params.replace(/&$/, '')}`
        return await invoke('GET', url)
    } catch (ex) {
        return false
    }
}

export const oauth = {
    authorize: async (query) => {
        try {
            let url = '/auth/authorize'
            const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?')
            url = `${url}${params.replace(/&$/, '')}`
            return await invoke('GET', url)
        } catch (ex) {
            return false
        }
    },

    decide: async (data) => {
        try {
            // TODO: returns redirect url with auth code
            return await invoke('POST', '/auth/authorize/decision', data)
        } catch (ex) {
            return false
        }
    }
}
