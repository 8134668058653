/* eslint-disable import/no-dynamic-require, global-require */
const ListProductRequests = (resolve) => require(['./list/ListProductRequests.vue'], resolve)
const CreateProductRequest = (resolve) => require(['./create/CreateProductRequest.vue'], resolve)
const ViewProductRequest = (resolve) => require(['./view/ViewProductRequest.vue'], resolve)
const EditProductRequest = (resolve) => require(['./edit/EditProductRequest.vue'], resolve)
/* eslint-enable import/no-dynamic-require, global-require */

export default [
    { path: '/product-requests/list', name: 'list-product-requests', component: ListProductRequests },
    { path: '/product-requests/create', name: 'create-product-request', component: CreateProductRequest },
    { path: '/product-requests/:id', name: 'view-product-request', component: ViewProductRequest },
    { path: '/product-requests/:id/edit', name: 'edit-product-request', component: EditProductRequest }
]
